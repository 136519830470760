<template>
  <div>
    <h2>Easter Eggs</h2>
    <p>Play with some Yuletide Main Collection 2021 stats!</p>
    <p>
      Stats may also contain errors if: fics were co-authored by more than one
      author, gifted to more than one person, were given to or recieved by
      orphaned accounts/anonymous users. Best effort attempts have been made to
      account for these edge cases!
    </p>
    <h3>Preset Filters</h3>
    <button class="btn" @click="filter('heroes')">No-Gift Heroes</button>
    <button class="btn" @click="filter('longfic')">
      Longfic (10K+ Words) Writers
    </button>
    <button class="btn" @click="filter('prolific')">
      Prolific (3 fics+) Writers
    </button>
    <button class="btn" @click="filter('nano')">
      NaNoYuleMo (50K+ Total Words)
    </button>
    <button class="btn" @click="filter('avg')">
      2+ Fics With High Average Words Warriors
    </button>
    <!-- <button class="btn" @click="filter('minichallenge')">
      Any Minichallenge
    </button> -->

    <div class="divider"></div>
    <h3>Roll Your Own</h3>

    <form class="form-horizontal col-8">
      <div class="form-group">
        <div class="col-5 col-sm-12">
          <label class="form-label" for="written">Min. Fics Written</label>
        </div>
        <div class="col-3 col-sm-12">
          <input
            class="form-input"
            type="text"
            id="written"
            number
            v-model="minWritten"
          />
        </div>
      </div>

      <div class="form-group">
        <div class="col-5 col-sm-12">
          <label class="form-label" for="total">Min. Total Words Written</label>
        </div>
        <div class="col-3 col-sm-12">
          <input
            class="form-input"
            type="text"
            id="total"
            number
            v-model="minTotal"
          />
        </div>
      </div>

      <div class="form-group">
        <div class="col-5 col-sm-12">
          <label class="form-label" for="avg">Min. Avg Words/Fic</label>
        </div>
        <div class="col-3 col-sm-12">
          <input
            class="form-input"
            type="text"
            id="avg"
            number
            v-model="minAvg"
          />
        </div>
      </div>

      <div class="form-group">
        <div class="col-5 col-sm-12">
          <label class="form-label" for="max"
            >Min. Wordcount For Fic With Most Words</label
          >
        </div>
        <div class="col-3 col-sm-12">
          <input
            class="form-input"
            type="text"
            id="max"
            number
            v-model="minMax"
          />
        </div>
      </div>

      <div class="form-group">
        <div class="col-5 col-sm-12">
          <label class="form-label" for="received">Max. Fics Received</label>
        </div>
        <div class="col-3 col-sm-12">
          <input
            class="form-input"
            type="text"
            id="received"
            number
            v-model="maxGifts"
          />
        </div>
      </div>

      <!-- <div class="form-group">
        <div class="col-5 col-sm-12">
          <label class="form-label" for="received">Minichallenge</label>
        </div>
        <div class="col-3 col-sm-12">
          <select v-model="minichallenge">
            <option value>Select one</option>
            <option>Any</option>
            <option>Crueltide</option>
            <option>Femslash Festivus</option>
            <option>IF</option>
            <option>MTYG</option>
            <option>T/NB/GQ</option>
            <option>Two For One</option>
            <option>Wrapping Paper</option>
            <option>Yuleporn</option>
          </select>
        </div>
      </div> -->
    </form>
    <!-- form structure -->

    <table class="bonus table">
      <thead>
        <tr>
          <th class="username">
            <a href="#" @click.stop.prevent="sort('username')">Username</a>
            &nbsp;
            <span
              :class="[
                'fas',
                sortBy === 'username'
                  ? sortOrder == 'asc'
                    ? 'fa-angle-up'
                    : 'fa-angle-down'
                  : '',
              ]"
              @click="sort('username')"
            ></span>
          </th>
          <th class="th-small">
            <a href="#" @click.stop.prevent="sort('written')">Fics Written</a>
            &nbsp;
            <span
              :class="[
                'fas',
                sortBy === 'written'
                  ? sortOrder == 'asc'
                    ? 'fa-angle-up'
                    : 'fa-angle-down'
                  : '',
              ]"
              @click="sort('written')"
            ></span>
          </th>
          <th>
            <a href="#" @click.stop.prevent="sort('totalWords')">Total Words</a>
            &nbsp;
            <span
              :class="[
                'fas',
                sortBy === 'totalWords'
                  ? sortOrder == 'desc'
                    ? 'fa-angle-down'
                    : 'fa-angle-up'
                  : '',
              ]"
              @click="sort('totalWords')"
            ></span>
          </th>
          <th>
            <a href="#" @click.stop.prevent="sort('avgWords')">Avg. Words</a>
            &nbsp;
            <span
              :class="[
                'fas',
                sortBy === 'avgWords'
                  ? sortOrder == 'desc'
                    ? 'fa-angle-down'
                    : 'fa-angle-up'
                  : '',
              ]"
              @click="sort('avgWords')"
            ></span>
          </th>
          <th>
            <a href="#" @click.stop.prevent="sort('maxWords')"
              >Fic With Most Words</a
            >
            &nbsp;
            <span
              :class="[
                'fas',
                sortBy === 'maxWords'
                  ? sortOrder == 'desc'
                    ? 'fa-angle-down'
                    : 'fa-angle-up'
                  : '',
              ]"
              @click="sort('maxWords')"
            ></span>
          </th>
          <th class="th-small">
            <a href="#" @click.stop.prevent="sort('received')"
              >Gifts Received</a
            >
            &nbsp;
            <span
              :class="[
                'fas',
                sortBy === 'received'
                  ? sortOrder == 'desc'
                    ? 'fa-angle-down'
                    : 'fa-angle-up'
                  : '',
              ]"
              @click="sort('received')"
            ></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="value in list" :key="value.username">
          <td>
            <router-link :to="'/user/' + value.username">{{
              value.username
            }}</router-link>
            <!-- <div v-if="value.challenges.length">
              <small>{{ value.challenges.join(", ") }}</small>
            </div> -->
          </td>
          <td>{{ value.written }}</td>
          <td>{{ value.totalWords }}</td>
          <td>{{ Math.round(value.avgWords) }}</td>
          <td>{{ value.maxWords }}</td>
          <td>{{ value.received }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import _ from "lodash";
import users from "../data/eastereggs.json";
export default {
  data() {
    return {
      users,
      sortBy: "written",
      minWritten: 2,
      minAvg: 0,
      minTotal: 0,
      minMax: 0,
      maxGifts: 100,
      sortOrder: "desc",
      minichallenge: ""
    };
  },
  methods: {
    sort(field) {
      if (this.sortBy === field) {
        if (this.sortOrder === "asc") {
          this.sortOrder = "desc";
        } else {
          this.sortOrder = "asc";
        }
      } else {
        this.sortBy = field;
      }
    },
    filter(type) {
      switch (type) {
        case "heroes":
          this.maxGifts = 0;
          this.minWritten = 1;
          this.minAvg = 0;
          this.minTotal = 0;
          this.minMax = 0;
          this.minichallenge = "";
          this.sortBy = "written";
          this.sortOrder = "desc";
          break;
        case "longfic":
          this.maxGifts = 100;
          this.minWritten = 1;
          this.minAvg = 0;
          this.minTotal = 0;
          this.minMax = 10000;
          this.minichallenge = "";
          this.sortBy = "maxWords";
          this.sortOrder = "desc";
          break;
        case "prolific":
          this.maxGifts = 100;
          this.minWritten = 3;
          this.minAvg = 0;
          this.minTotal = 0;
          this.minMax = 0;
          this.minichallenge = "";
          this.sortBy = "written";
          this.sortOrder = "desc";
          break;
        case "avg":
          this.maxGifts = 100;
          this.minWritten = 2;
          this.minAvg = 2000;
          this.minTotal = 0;
          this.minMax = 0;
          this.minichallenge = "";
          this.sortBy = "avgWords";
          this.sortOrder = "desc";
          break;
        case "nano":
          this.maxGifts = 100;
          this.minWritten = 0;
          this.minAvg = 0;
          this.minTotal = 50000;
          this.minMax = 0;
          this.minichallenge = "";
          this.sortBy = "totalWords";
          this.sortOrder = "desc";
          break;
        case "minichallenge":
          this.maxGifts = 100;
          this.minWritten = 0;
          this.minAvg = 0;
          this.minTotal = 0;
          this.minMax = 0;
          this.sortBy = "username";
          this.minichallenge = "Any";
          this.sortOrder = "asc";
          break;
        default:
          break;
      }
    }
  },
  computed: {
    list() {
      let filtered = _.filter(this.users, u => {
        return (
          u.written >= this.minWritten &&
          u.avgWords >= this.minAvg &&
          u.totalWords >= this.minTotal &&
          u.maxWords >= this.minMax &&
          u.received <= this.maxGifts
        );
      });

      if (this.minichallenge.length) {
        filtered = _.filter(filtered, u => {
          if (this.minichallenge === "Any") {
            return u.challenges.length > 0;
          }
          return _.includes(u.challenges, this.minichallenge);
        });
      }

      const res = _.sortBy(filtered, u => u[this.sortBy]);
      return this.sortOrder == "desc" ? _.reverse(res) : res;
    }
  }
};
</script>

<style scoped lang="scss">
.btn {
  margin: 10px 10px 0 0;
}

// .th-small {
//   width: 80px;
// }

.username {
  width: 150px;
}
</style>

